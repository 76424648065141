import gql from 'graphql-tag'

// Id and creation date
export const EnvironmentCreateFragment = gql`
  fragment EnvironmentCreate on Environment {
    _id
    createdAt
  }
`

// General data
export const EnvironmentGeneralUpdateFragment = gql`
  fragment EnvironmentGeneralUpdate on Environment {
    name
    url
    timezone
    analyticsId
    allowSignUp
    signUpRoles
    signUpScript
    enableTellus
    tellusChannels
    autoEnableNotifications
    notificationPrompt
  }
`
// Appearance
export const EnvironmentAppearanceUpdateFragment = gql`
  fragment EnvironmentAppearanceUpdate on Environment {
    allowDarkMode
    useCustomCss
    customCss
    accentColor
    sideBarColor
    sideBarTextColor
    backgroundColor
    logo {
      _id
      name
      type
    }
    loginLogo {
      _id
      name
      type
    }
    authBackgroundImage {
      _id
      name
      type
    }
    icon {
      _id
      name
      type
    }
  }
`

export const EnvironmentProfileFieldsUpdateFragment = gql`
  fragment EnvironmentProfileFieldsUpdate on Environment {
    profileFields {
      name
      type
      label
      options
    }
  }
`

export const EnvironmentFragment = gql`
  fragment Environment on Environment {
    ...EnvironmentCreate
    ...EnvironmentGeneralUpdate
    ...EnvironmentAppearanceUpdate
  }
  ${EnvironmentCreateFragment}
  ${EnvironmentGeneralUpdateFragment}
  ${EnvironmentAppearanceUpdateFragment}
`
